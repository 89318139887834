import { populateFirmStore } from 'api';
import cloneDeep from 'lodash/cloneDeep';
import { makeAutoObservable } from 'mobx';

import { convertDateStringToDate } from 'utils/date';

class FirmStore {
  loading = false;

  error = false;

  id = 0;

  name = '';

  malpracticeExpireDate = '';

  disabledByMalpractice = false;

  tipaltiId = '';

  newCasesAllowed = true;

  get malpracticeExpired() {
    if (this.malpracticeExpireDate) {
      // To get rid of the time when comparing the two dates
      const today = new Date(new Date().toDateString());
      const expire = convertDateStringToDate(this.malpracticeExpireDate);
      return expire - today < 0;
    }

    return true;
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setState(data) {
    Object.keys(data).forEach(field => {
      this[field] = cloneDeep(data[field]);
    });
  }

  loadFirm(id) {
    this.loading = true;
    if (id) {
      populateFirmStore(id)
        .then(response => {
          this.setState(response.data);
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}

export default FirmStore;
