import client from 'api/client';

import createURLParams from 'utils/urls';

// Kicks
export const getKickDetails = async ({ queryForHardKicks }) => {
  const params = createURLParams({ hard_kick: queryForHardKicks });
  const { data } = await client.get('kicks/', { params });
  return data;
};

export const findAndUpdateKick = async (id, payload) => {
  const { data } = await client.patch(`kicks/${id}/`, payload);
  return data;
};

// KickInstances
export const reportErrorKick = payload =>
  client.post(`kicks/kick-instances/report-error-kick/`, payload);
