import client from 'api/client';

import { LOGOUT_URL } from 'utils/constants';
import { withReturn } from 'zg_utils/tokens';

export const addUser = async payload => {
  const { data } = await client.post(`users/`, payload);
  return data;
};
export const deleteUser = async pk => {
  const { data } = await client.delete(`users/${pk}/`);
  return data;
};
export const addClaimsReviewer = payload =>
  client.post('users/', payload, { params: { isClaimsReviewer: true } });

export const getCurrentUser = async () => {
  const { data } = await client.get('users/current/');
  return data;
};

export const findAndUpdateUser = async payload => {
  const { data } = await client.patch(`users/${payload.id}/`, payload);
  return data;
};

export const updateLoginEmail = (id, payload) => client.post(`users/${id}/reset-email/`, payload);

export const getAllPermissions = payload =>
  client.get('users/all-permissions/', { params: payload });

export const getClaimsReviewers = () => client.get(`users/claims-reviewers/`);

export const checkEligibilityEvent = (id, payload) =>
  client.post(`users/${id}/check-eligibility-event/`, payload);

export const getLoginDetails = id => client.post(`users/${id}/login-details/`);

export const logoutUser = async () => {
  window.location = withReturn(await LOGOUT_URL, false);
};
