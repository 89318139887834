import client from 'api/client';

export const addOffice = payload => client.post(`firms/offices/`, payload);

export const findAndUpdateOffice = (id, payload) => client.patch(`firms/offices/${id}/`, payload);

export const deleteOffice = id => client.delete(`firms/offices/${id}/`);

export const getPendingOffices = () => client.get('firms/offices/?fsm_state=SUBMITTED');

export const approveOffice = id => client.patch(`firms/offices/approve-office/`, { id });

export const denyOffice = id => client.patch(`firms/offices/deny-office/`, { id });

export const validateOfficeData = payload => client.post('firms/offices/validate-office/', payload);

export const changePrimaryOffice = (officeId, firmId) =>
  client.patch(`firms/${firmId}/change-primary-office/`, { new_primary_office_id: officeId });
