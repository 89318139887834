import { getFeeScheduleDocument, getMiscDocument } from 'api';

const DOCUMENTS_AND_MANUALS = [
  {
    category: 'PLF Manuals',
    cards: [
      {
        label: 'Eligibility, Service, and Claim IDs',
        pdfLink: '/pdfs/eid.pdf',
        thumbnail: 'pdfs/thumbs/metlife-logo.png',
      },
      {
        label: 'Participating Law Firm Manual',
        pdfLink: '/pdfs/plfmanual.pdf',
        thumbnail: '/pdfs/thumbs/plfmanual-thumb.png',
      },
      {
        label: 'Closing Schedule',
        thumbnail: '/pdfs/thumbs/closingschedule-thumb.png',
        documentType: 'closingschedule',
        onClick: getMiscDocument,
      },
      {
        label: 'Fee Schedule',
        thumbnail: '/pdfs/thumbs/fee-schedule-thumb.png',
        onClick: getFeeScheduleDocument,
      },
    ],
  },

  {
    category: 'Billing Instructions',
    cards: [
      {
        label: 'Printable PSR',
        pdfLink: '/pdfs/paper-psr.pdf',
        thumbnail: '/pdfs/thumbs/paper-psr-thumb.png',
      },
    ],
  },

  {
    category: 'Plan Information',
    cards: [
      {
        label: 'Group Will Preparation Service',
        pdfLink: '/pdfs/groupwill.pdf',
        thumbnail: '/pdfs/thumbs/groupwill-thumb.png',
      },
    ],
  },
];

export default DOCUMENTS_AND_MANUALS;
