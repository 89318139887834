import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const feeReimbursementsQueryKey = 'getFeeReimbursements';

export const getFeeReimbursements = ({ limit = 0, offset = 0, sortBy = '', params = {} }) => {
  const queryParams = createURLParams({ limit, offset, ordering: sortBy, ...params });
  return client.get(`fee-reimbursements/`, { params: queryParams });
};

export const getFeeReimbursementDocument = (id, payload) =>
  CLIENT_GET_FILE.get(`fee-reimbursements/documents/${id}/`, payload);

export const uploadFeeReimbursementDocument = payload =>
  CLIENT_POST_FILE.post('fee-reimbursements/documents/', payload);

export const deleteFeeReimbursement = id => client.delete(`fee-reimbursements/${id}/`);

export const deleteFeeReimbursementDocument = id =>
  client.delete(`fee-reimbursements/documents/${id}/`);

export const createFeeReimbursement = payload => client.post('fee-reimbursements/', payload);

export const updateFeeReimbursement = async ({ id, payload }) =>
  client.patch(`fee-reimbursements/${id}/`, payload);

export const createPayee = (id, payload) => client.post(`fee-reimbursements/${id}/payee/`, payload);
