// Not billable services:
export const NOT_BILLABLE_CASE_TYPES = [330, 350, 360, 670];

// Case/Service statuses
export const IN_PROGRESS = 'In Progress';
export const CLOSED = 'Closed';
export const ABANDONED = 'Client abandoned service';

// Claim statuses
export const CLAIM_UNDER_REVIEW = 'Claim Under Review';
export const CLAIM_PENDING_APPROVAL = 'Claim Pending Approval';
export const CLAIM_SUBMITTED = 'Claim Submitted';
export const CLAIM_REJECTED = 'Claim Rejected';
export const CLAIM_APPROVED = 'Claim Approved';
export const CLAIM_WITHDRAWN = 'Claim Withdrawn';
export const CLAIM_PAYMENT_SENT = 'Claim Sent to Tipalti';
export const CLAIM_REVIEWED_BY_COUNSEL = 'Reviewed by Counsel';
export const REVIEWED_CLAIMS = 'Reviewed Claims';
export const STATUS_CHOICES = [
  CLAIM_APPROVED,
  CLAIM_PENDING_APPROVAL,
  CLAIM_REJECTED,
  CLAIM_SUBMITTED,
  CLAIM_WITHDRAWN,
  CLAIM_UNDER_REVIEW,
  CLAIM_PAYMENT_SENT,
];
export const REVIEW_STATUSES = [CLAIM_PENDING_APPROVAL, CLAIM_UNDER_REVIEW];
export const CLOSED_STATUSES = [
  CLAIM_APPROVED,
  CLAIM_REJECTED,
  CLAIM_WITHDRAWN,
  CLAIM_PAYMENT_SENT,
];
export const APPROVED_STATUSES = [CLAIM_APPROVED, CLAIM_PAYMENT_SENT];

// Claim reviewer status
export const UNASSIGNED = 'Unassigned';

// Claim actions
export const PAID = 'paid';
export const APPROVED = 'approved';

// Query params
export const QUERY_PARAM_CLOSED = 'closed';
export const QUERY_PARAM_OPEN = 'open';

// Kick instance statuses
export const NOT_REVIEWED = 'Not reviewed';
export const DID_NOT_RUN = 'Kick did not execute';
export const KEPT = 'Claim rejected';
export const DISCARDED = 'Kick ignored';

// Kick statuses
export const IGNORE = 'Ignore';

// Kick Handling Options
export const KICK_HANDLING_OPTIONS = [
  {
    value: 'Reject',
    displayValue: 'Always keep this kick and reject the claim.',
  },
  {
    value: 'Ignore',
    displayValue: 'Always discard this kick.',
  },
  {
    value: 'Manual',
    displayValue: 'Always keep this kick and send the claim to manual review.',
  },
];

// Kick parameterType options
export const INT_TYPES = ['int', 'list_int'];
export const FLOAT_TYPES = ['float', 'list_float'];
