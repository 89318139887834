import { getAllServices } from 'api';

import { computedFn } from 'mobx-utils';

class ServicesStore {
  categories = [];

  services = [];

  loading = true;

  error = false;

  loadServices() {
    this.loading = true;
    getAllServices()
      .then(response => {
        // set services
        this.services = response.data;
        // compute categories by looking at services data
        const categories = new Set();
        response.data.forEach(service => {
          categories.add(service.category);
        });
        this.categories = [...categories];
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // Return the id for the given service name(s)
  getServiceId = computedFn(services => {
    if (typeof services === 'string') {
      return this.services.find(s => s.name === services).id;
    }
    const serviceIds = [];
    services.forEach(service => serviceIds.push(this.services.find(s => s.name === service).id));
    return serviceIds;
  });

  // Return the name for the given service caseType(s)
  getServiceName = computedFn(caseTypes => {
    if (typeof caseTypes === 'string') {
      return this.services.find(s => s.caseType === caseTypes).name;
    }
    const serviceNames = [];
    caseTypes.forEach(caseType =>
      serviceNames.push(this.services.find(s => s.caseType === caseType).name),
    );
    return serviceNames;
  });

  // Categorize the given list of services
  categorizeServices = computedFn(servicesList => {
    const retval = {};
    this.categories.forEach(category => {
      retval[category] = servicesList?.filter(value =>
        this.servicesByCategory[category].includes(value),
      );
    });
    return retval;
  });

  get serviceOptions() {
    if (this.loading) {
      return [];
    }
    return this.services.map(service => {
      return [service.id, service.name];
    });
  }

  // Return all services grouped by category
  get servicesByCategory() {
    if (this.loading) {
      return {};
    }
    const result = {};
    // Create a entry in the dict for each category
    this.categories.forEach(category => {
      result[category] = [];
    });
    // Add services to the corresponding category entry
    this.services.forEach(service => {
      result[service.category].push(service.name);
    });
    return result;
  }
}

export default ServicesStore;
