import { makeAutoObservable } from 'mobx';
import AppStore from 'stores/app';
import FirmStore from 'stores/firm';
import ServicesStore from 'stores/services';

class RootStore {
  app = new AppStore();

  services = new ServicesStore();

  firm = new FirmStore();

  constructor() {
    this.app = new AppStore(this);
    this.services = new ServicesStore(this);
    this.firm = new FirmStore(this);

    makeAutoObservable(this);
  }
}

export default RootStore;
