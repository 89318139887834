import { observer } from 'mobx-react';

import { makeStyles } from '@bequestinc/wui';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';

const useStyles = makeStyles()({
  text: {
    padding: 40,
  },
});

const NoAccess = () => {
  const { classes } = useStyles();

  return (
    <Page title="Access Denied">
      <Typography variant="h1" align="center" className={classes.text}>
        Access Denied
      </Typography>

      <Typography variant="h2" align="center" className={classes.text}>
        You do not have access to view this page!
      </Typography>
    </Page>
  );
};

export default observer(NoAccess);
