const createURLParams = data => {
  const params = new URLSearchParams();
  Object.keys(data).forEach(key => {
    const isEmptyArray = Array.isArray(data[key]) && data[key].length === 0;
    if (data[key] != null && data[key] !== '' && !isEmptyArray) {
      params.append(key, data[key]);
    }
  });
  return params;
};

export default createURLParams;
