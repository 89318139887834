import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const submitClaim = payload => CLIENT_POST_FILE.post('claims/submit/', payload);

export const deleteClaim = id => client.delete(`claims/${id}/`);

export const findAndUpdateClaim = (id, payload, params = {}) =>
  client.patch(`claims/${id}/`, payload, { params });

export const getClaimHistoryData = id => client.get(`claims/${id}/history/`);

export const getClaims = ({ limit = 0, offset = 0, params = {}, sortBy = '' }) => {
  const queryParams = createURLParams({ limit, offset, ordering: sortBy, ...params });
  return client.get('claims/', { params: queryParams });
};

export const getClaimDetails = pk => client.get(`claims/${pk}/`);

export const getClaimDocument = (id, payload) =>
  CLIENT_GET_FILE.get(`claims/documents/${id}/`, payload);

export const uploadClaimDocument = payload => CLIENT_POST_FILE.post(`claims/documents/`, payload);

export const removeClaimDocument = id => client.delete(`claims/documents/${id}/`);

export const sendClaimContents = (id, payload) => client.patch(`claims/${id}/email/`, payload);

export const resendClaimRejectionEmail = (id, payload) =>
  client.patch(`claims/${id}/resend-claim-rejection-email/`, payload);

export const getPayments = firmId => client.get(`firms/${firmId}/payments/`);

export const sendFeeReimbursementPayments = () =>
  client.post(`claims/send-fee-reimbursement-payments/`);

export const sendPaymentEmails = () => client.post('claims/send-payment-emails/');

export const getRecentClaimsByMember = id => client.get(`claims/${id}/recent/`);

export const getPaymentsForMailing = async () => {
  const { data } = await client.get('claims/get-payments-for-mailing/');
  return data;
};

export const emailFrClientStatements = async payload => {
  const { data } = await client.post('claims/send-client-statements/', payload);
  return data;
};
