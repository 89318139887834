import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

export const addAttorney = payload => client.post('users/attorneys/', payload);

export const findAndUpdateAttorney = (id, payload) =>
  client.patch(`users/attorneys/${id}/`, payload);

export const validateAttorneyData = payload =>
  client.post('users/attorneys/validate-attorney/', payload);

export const updateAttorneyProfilePicture = (pk, payload) =>
  CLIENT_POST_FILE.post(`users/attorneys/${pk}/update-attorney-photo/`, payload);

export const getAttorneyProfilePicture = pk =>
  CLIENT_GET_FILE.get(`users/attorneys/${pk}/attorney-photo/`);

export const getAttorneyInfoOptions = id =>
  client.post('users/attorneys/options/', { firm_id: id });

export const changePrimaryAttorney = payload =>
  client.post('users/attorneys/change-primary-attorney/', payload);
