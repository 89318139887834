import { observer } from 'mobx-react';

import { makeStyles } from '@bequestinc/wui';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';

const useStyles = makeStyles()({
  error: {
    margin: 'auto',
    padding: 100,
    textAlign: 'center',
  },
});

const ErrorPage = () => {
  const { classes } = useStyles();

  return (
    <Page title="Error">
      <div className={classes.error}>
        <Typography align="center">Oops! Something went wrong...</Typography>
      </div>
    </Page>
  );
};

export default observer(ErrorPage);
