import client from 'api/client';

export const eligibilityCheck = payload => client.post('members/eligibility-check/', payload);

export const getCoverageByCodes = coverageCodes =>
  client.post('members/coverage-code-lookup/', { coverageCodes });

export const searchByCaseNumber = payload =>
  client.post('members/case-number-eligibility/', payload);

export const searchByMemberNumber = payload =>
  client.post('members/member-number-eligibility/', payload);

export const getPreviousClients = firmId => client.get(`members/previous-clients/?firm=${firmId}`);

export const createMembersEntry = payload => client.post('members/create-member/', payload);

export const getClaimsByMember = payload => client.post('members/claims-for-member/', payload);

export const checkCoverageAtDate = (mlpId, date) =>
  client.get(`members/coverage-by-date/?mlp_id=${mlpId}&date=${date}`);
