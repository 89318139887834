import { useQuery } from '@tanstack/react-query';

import { getCurrentUser } from 'api';

export const getCurrentUserQueryKey = ['get-current-user'];

export const useCurrentUserQuery = (overrides = {}) =>
  useQuery({
    queryKey: getCurrentUserQueryKey,
    queryFn: getCurrentUser,
    staleTime: Infinity,
    ...overrides,
  });
