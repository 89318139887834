import client from 'api/client';

export const getAllServices = () => client.get('services/all-services/');

export const getEnhancementPlanNames = async codes => {
  const { data } = await client.post('services/enhancement-plan-name/', { codes });
  return data;
};

export const getServiceGroupParameters = serviceTypeId =>
  client.get(`services/service-group-parameters/${serviceTypeId}/`);

export const findAndUpdateServiceGroupParameters = ({ serviceTypeId, payload }) =>
  client.patch(`services/service-group-parameters/${serviceTypeId}/`, payload);

export const createServiceGroupParams = payload =>
  client.post(`services/service-group-parameters/`, payload);
