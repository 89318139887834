export const EMAIL = 'Email and Zip Code';
export const MEMBERSHIP_ID = 'Membership ID';
export const ELIGIBILITY_ID = 'Eligibility ID';
export const PREVIOUS_CLIENT = 'Previous Client';
export const CASE_NUMBER = 'Case Number';

export const MEMBER = 'member';
export const SPOUSE = 'spouse';
export const DEPENDENT = 'dependent';
export const SPECIAL_NEEDS_DEPENDENT = 'special needs dependent';

export const COVERAGE_CATEGORIES = [
  [MEMBER, 'Member'],
  [DEPENDENT, 'Dependent'],
  [SPOUSE, 'Spouse'],
  ['parent', 'Parent'],
];

export const VERIFICATION_METHOD_LABEL = "Choose how to verify the client's coverage";
export const VERIFICATION_DATA_LABEL = 'Verify membership';
export const CLIENTS_LABEL = 'Select clients';
export const SERVICES_LABEL = 'Select legal services';
export const ENHANCEMENT_PLAN_LABEL = 'Select Enhancement Plan to view coverage for';
export const CREATE_LABEL = 'Add Member Contact Details';
export const COVERAGE_LOOKUP_LABEL = 'Coverage Lookup';
