import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useGlobalContext } from 'hooks';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import { ATTORNEY_MENU_ITEMS, PANEL_MENU_ITEMS } from 'utils/constants';

import NoAccess from 'components/NoAccess';
import ErrorPage from 'pages/error';

const RouteGuard = ({ children }) => {
  const router = useRouter();
  const { app, firm, services } = useGlobalContext();

  const plfUserLinks = ATTORNEY_MENU_ITEMS.map(item => item.href);
  const panelUserLinks = PANEL_MENU_ITEMS.map(item => item.href);

  const { data: user, isFetching, isError } = useCurrentUserQuery();

  useEffect(() => {
    services.loadServices();
  }, []);

  useEffect(() => {
    if (isFetching) return;
    if (!user.isPanelUser) {
      firm.loadFirm(user.firm);
    }
  }, [user, isFetching]);

  const userHasPermissionToLink = (path, userPermissions) => {
    const link = ATTORNEY_MENU_ITEMS.concat(PANEL_MENU_ITEMS).find(item => item.href === path);
    if (link && link.permissions) return userPermissions.some(i => link.permissions.includes(i));
    return true;
  };

  const restrictedAccessComponent = () => {
    const restrictedAccessRules = {
      panelUser: {
        onRestrictedRoute: panelUserLinks.includes(router.pathname),
        inRestrictedRole:
          !user.isPanelUser || !userHasPermissionToLink(router.pathname, user.groups),
        placeholderComponent: <NoAccess />,
      },
      plfUser: {
        onRestrictedRoute: plfUserLinks.includes(router.pathname),
        inRestrictedRole:
          !(user.isFirmUser || user.isAttorneyUser) ||
          !userHasPermissionToLink(router.pathname, user.groups),
        placeholderComponent: <NoAccess />,
      },
    };

    const restrictedAccess = Object.values(restrictedAccessRules).find(
      rule => rule.onRestrictedRoute && rule.inRestrictedRole,
    );

    return restrictedAccess ? restrictedAccess.placeholderComponent : '';
  };

  // No need to wait for user data if route already isn't valid.
  if (router.route === '/404') {
    return <ErrorPage />;
  }

  if (app.error) {
    return <ErrorPage />;
  }

  // If route is valid, wait for user to populated before deciding what to show.
  if (isFetching) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (isError) {
    return <ErrorPage />;
  }
  return restrictedAccessComponent() || children;
};

RouteGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(RouteGuard);
