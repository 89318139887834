import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const FloatingUIPopper = ({ renderAnchorElement, renderContent, location, open }) => {
  const [isOpen, setIsOpen] = useState(open);

  const { refs, floatingStyles, context, placement } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: location,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      <Box ref={refs.setReference}>{renderAnchorElement(getReferenceProps())}</Box>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <Box
            ref={refs.setFloating}
            style={{ zIndex: 1, ...floatingStyles }}
            {...getFloatingProps()}
          >
            {renderContent({ placement, isOpen, setIsOpen })}
          </Box>
        </FloatingFocusManager>
      )}
    </>
  );
};

FloatingUIPopper.propTypes = {
  renderAnchorElement: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  location: PropTypes.string,
  open: PropTypes.bool,
};

FloatingUIPopper.defaultProps = {
  location: 'bottom',
  open: false,
};

export default observer(FloatingUIPopper);
