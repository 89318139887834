import {
  CAN_APPROVE_KICKS,
  CAN_BROADCAST_MEMOS,
  CAN_DO_INTAKE,
  CAN_MANAGE_DOCUMENTS,
  CAN_MANAGE_PANEL_USERS,
  INTERNAL_USERS,
  PANEL_USERS,
} from './permissions';

export const ATTORNEY_MENU_ITEMS = [
  {
    href: '/check-eligibility',
    description: 'Check Client Eligibility',
    permissions: CAN_DO_INTAKE,
    style: 'big',
    hideIfCantTakeNewCases: true,
    tooltipText:
      'On this page you can check that clients are eligible for MetLife Legal Plans services and view their coverage information to ensure they are covered for their desired legal services.',
  },
  {
    href: '/my-cases',
    description: 'My Cases',
    showIfMalpracticeExpired: true,
    style: 'big',
    tooltipText:
      'On this page you can track ongoing cases that you have with MetLife Legal Plans covered clients. You can see statuses for your ongoing work and submit claims here.',
  },

  {
    href: '/billing-history',
    description: 'Billing History',
    showIfMalpracticeExpired: true,
    style: 'big',
    tooltipText:
      "On this page you can view a history of all of the payments you've received from MetLife Legal Plans.",
  },
  {
    href: '/firm-info',
    description: 'Edit Firm Data',
    showIfMalpracticeExpired: true,
    style: 'big',
    tooltipText:
      'On this page you can manage the information we have about your firm. This includes your payment information, any users registered with your firm, and the information about services that you provide that will be shown to our members.',
  },
  {
    href: '/links',
    description: 'Documents & Manuals',
    showIfMalpracticeExpired: true,
    style: 'big',
    tooltipText:
      'On this page you can find all of the MetLife Legal Plans reference documents and materials.',
  },
  {
    href: '/faq',
    description: 'FAQs',
    showIfMalpracticeExpired: true,
    style: 'small',
  },
  {
    href: '/my-panel-rep',
    description: 'Contact Panel Rep',
    showIfMalpracticeExpired: true,
    style: 'small',
    tooltipText:
      'On this page you can find details about your designated Panel Representative(s), including contact information.',
  },
];

export const PANEL_MENU_ITEMS = [
  {
    href: '/search-firms',
    description: 'Search Firms',
    permissions: [PANEL_USERS, CAN_APPROVE_KICKS],
    style: 'big',
  },
  {
    href: '/panel-users',
    description: 'Manage Users',
    permissions: CAN_MANAGE_PANEL_USERS,
    style: 'big',
  },
  {
    href: '/memos',
    description: 'Manage Memos',
    permissions: CAN_BROADCAST_MEMOS,
    style: 'big',
  },
  {
    href: '/panel-inbox',
    description: 'My Inbox',
    style: 'big',
  },
  {
    href: '/tableau',
    description: 'View Tableau Reports',
    permissions: INTERNAL_USERS,
    style: 'big',
  },
  {
    href: '/edit-documents',
    description: 'Edit Documents',
    permissions: CAN_MANAGE_DOCUMENTS,
    style: 'big',
  },
  {
    href: '/my-panel-info',
    description: 'My Contact Info',
    permissions: PANEL_USERS,
    style: 'small',
  },
];
