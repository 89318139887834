import { usaStates } from 'typed-usa-states';

export const ACTIVE = 'Active';
export const NOT_ACTIVE = 'Not Active';
export const DISABLED = 'Disabled';
export const DELETED = 'Deleted';
export const FIRM_STATUSES = [ACTIVE, NOT_ACTIVE];
export const ON_HOLD = 'On Hold';

export const DEFAULT_PROFILE_PICTURE_FILE = '/images/empty-profile-picture.png';

export const EXCLUDED_STATE_ABBREVIATIONS = ['FM', 'MH', 'PW', 'MP'];

export const US_STATES = usaStates.filter(
  state => !EXCLUDED_STATE_ABBREVIATIONS.includes(state.abbreviation),
);

export const STAFF_PAGE = 'Staff';
export const OFFICE_PAGE = 'Office';
export const ATTORNEY_PAGE = 'Attorney';
export const FIRM_PAGE = 'Firm';

export const LANGUAGES = [
  'English',
  'Afrikaans',
  'Arabic',
  'Armenian',
  'Ashanti',
  'Assyrian',
  'Bosnian',
  'Bulgarian',
  'Cambodian',
  'Chinese',
  'Creole',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'Farsi',
  'Filipino',
  'Flemish',
  'French',
  'German',
  'Greek',
  'Gujarati',
  'Haitian',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Igbo',
  'Ilocano',
  'Indian',
  'Italian',
  'Japanese',
  'Korean',
  'Latvian',
  'Lebanese',
  'Lithuanian',
  'Mandarin',
  'Navajo',
  'Norwegian',
  'Pakistan',
  'Polish',
  'Portuguese',
  'Punjabe',
  'Russian',
  'Serbian',
  'Sign Language',
  'Slovak',
  'Spanish',
  'Swedish',
  'Tagalog',
  'Taiwanese',
  'Turkish',
  'Ukranian',
  'Vietnamese',
  'Yiddish',
];

export const FIELD_REQUIRED_MESSAGE = 'This field is required';
export const BAR_NUMBER_REQUIRED_MESSAGE = 'At least one Bar Number required';

export const UPDATE = 'Update';
export const REGISTER = 'Register';
export const INTAKE = 'Intake';
export const ADD = 'Add';

// Phone number should either be in the format (XXX) XXX-XXXX or just a 10 digit string
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]\d{4}$|^\d{10}$/;

export const ZIP_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;

export const OFFICE_SUBMITTED = 'SUBMITTED';
export const OFFICE_DENIED = 'DENIED';
export const OFFICE_APPROVED = 'APPROVED';

export const FIRM_INFO_TITLE = 'Firm Information';
export const FIRM_BILLING_HISTORY_TITLE = 'Billing History';
export const FIRM_CLAIMS_TITLE = 'Claims';
export const FIRM_EXTRA_FEES_TITLE = 'Extra Fees';
