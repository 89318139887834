import { observer } from 'mobx-react';

import classNames from 'classnames';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import Paper from '@mui/material/Paper';

import BackButton from 'components/BackButton';
import NavBar from 'components/NavBar';

const useStyles = makeStyles()({
  root: {
    height: '100vh',
    overflow: 'auto',
  },
});

const Page = ({ children, title, showBackButton, className: externClassName, ...props }) => {
  const { classes } = useStyles();

  return (
    <>
      <Head>
        <title>{title} | MetLife Legal Plans</title>
      </Head>
      <NavBar />
      <Paper className={classNames(classes.root, externClassName)} role="main" {...props}>
        {showBackButton && <BackButton />}
        {children}
      </Paper>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  className: PropTypes.string,
};

Page.defaultProps = {
  showBackButton: true,
  className: '',
};

export default observer(Page);
