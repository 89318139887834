import { CacheProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enableStaticRendering, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import createCache from '@emotion/cache';
import { Integrations } from '@sentry/tracing';
import { logoutUser } from 'api';
import AxeCore from 'axe-core';
import GlobalContextProvider from 'global-context';
import 'global.css';
import { configure } from 'mobx';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { theme } from '@bequestinc/wui';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import env from 'utils/env';
import IdleSessionHandler from 'zg_utils/IdleSessionHandler';
import tokenFetcher, { withReturn } from 'zg_utils/tokens';

import ErrorFallback from 'components/ErrorFallback';
import RouteGuard from 'components/RouteGuard';

const isSsr = typeof window === 'undefined';

if (!isSsr) {
  Promise.all([env.sentryDsn, env.sentryEnvironment])
    .then(([dsn, environment]) => {
      Sentry.init({ dsn, environment, integrations: [new Integrations.BrowserTracing()] });
    })
    .catch(() => null);
}

enableStaticRendering(isSsr);
configure({ enforceActions: 'never' });

const appTheme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          maxWidth: 200,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey['300']}`,
          borderRadius: 3,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey['100'],
          textTransform: 'uppercase',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 14,
        },
      },
    },
  },
});

/**
 * Accessibility tool - outputs to devtools console on dev only and client-side only.
 * @see https://github.com/dequelabs/axe-core-npm/tree/develop/packages/react
 * For full list of a11y rules:
 * @see https://github.com/dequelabs/axe-core/blob/develop/doc/rule-descriptions.md
 */
if (process.env.NODE_ENV !== 'production' && !isSsr) {
  import('@axe-core/react').then(axe => {
    const config = {
      rules: AxeCore.getRules(['wcag21aa', 'wcag2aa', 'wcag2a']).map(rule => ({
        ...rule,
        id: rule.ruleId,
        enabled: true,
      })),
      disableOtherRules: true,
    };

    axe.default(React, ReactDOM, 1000, config);
  });
}

const muiCache = createCache({
  key: 'mui',
  prepend: 'true',
});

const queryClient = new QueryClient();

const App = ({ Component, pageProps, emotionCache = muiCache }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  useEffect(() => {
    env.portunusUrl.then(url => {
      if (url) {
        const onErrorHandler = () => {
          window.location.replace(withReturn(`${url}/login`));
        };
        tokenFetcher.start(url, null, null, onErrorHandler);
      }
    });

    return () => tokenFetcher.clearToken();
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GlobalContextProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />

            <title>MetLife Legal Plans</title>
          </Head>

          <CssBaseline />
          <ThemeProvider theme={appTheme}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={error => Sentry.captureException(error)}
            >
              <RouteGuard>
                <Component {...pageProps} />
              </RouteGuard>
              <IdleSessionHandler expirationHandler={logoutUser} />
            </ErrorBoundary>
          </ThemeProvider>
        </GlobalContextProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
  emotionCache: PropTypes.object,
};

App.defaultProps = {
  pageProps: {},
  emotionCache: muiCache,
};

export default observer(App);
