import client from 'api/client';

export const getMemos = () => client.get('memos/');

export const getVisibleMemos = () => client.get(`memos/visible/`);

export const deleteMemo = memoId => client.delete(`memos/${memoId}/`);

export const createMemo = payload => client.post(`memos/`, payload);

export const findAndUpdateMemo = (id, payload) => client.patch(`memos/${id}/`, payload);
