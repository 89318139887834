import getEnv from 'zg_utils/getEnv';

const keys = [
  'portunusUrl',
  'tipaltiUrl',
  'tipaltiMasterKey',
  'sentryDsn',
  'sentryEnvironment',
  'supportPhoneNumber',
  'production',
  'coverageChartTableauUrl',
  'caseNumberLookupTableauUrl',
  'enrollmentHistoryTableauUrl',
  'eligibilityIdLookupTableauUrl',
  'claimsLookupTableauUrl',
  'legacyExtraFeesTableauUrl',
];

export default getEnv(keys);
