import env from 'utils/env';

export const TABLEAU_API =
  'https://embedding.tableauusercontent.com/tableau.embedding.3.0.0.min.js';

export const getCoverageChartTableauUrl = () => env.coverageChartTableauUrl;

export const getCaseNumberLookupTableauUrl = () => env.caseNumberLookupTableauUrl;

export const getEnrollmentHistoryTableauUrl = () => env.enrollmentHistoryTableauUrl;

export const getEligibilityIdLookupTableauUrl = () => env.eligibilityIdLookupTableauUrl;

export const getClaimsLookupTableauUrl = () => env.claimsLookupTableauUrl;

export const getLegacyExtraFeesTableauUrl = () => env.legacyExtraFeesTableauUrl;
