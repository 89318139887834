import client, { CLIENT_GET_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const getServices = ({ limit = 0, offset = 0, params = {}, sortBy = '' }) => {
  // limit=0 returns all results when PAGE_MAX is not defined in DRF pagination settings
  const queryParams = createURLParams({ limit, offset, ordering: sortBy, ...params });
  return client.get('cases/', { params: queryParams });
};

export const getServiceDetails = pk => client.get(`cases/${pk}/`);

export const getServiceCoverage = pk => client.post(`cases/${pk}/coverage-lookup/`);

export const getCoverageDocument = pk => CLIENT_GET_FILE.get(`cases/${pk}/get-coverage-pdf/`);

export const findAndUpdateService = (id, payload) => client.patch(`cases/${id}/`, payload);

export const createService = payload => client.post(`cases/in-network/`, payload);

export const createOutOfNetworkService = payload => client.post(`cases/`, payload);

export const reopenService = id => client.patch(`cases/${id}/reopen-service/`);
