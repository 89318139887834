import { makeAutoObservable } from 'mobx';

class AppStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }
}

export default AppStore;
