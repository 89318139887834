import Router from 'next/router';
import PropTypes from 'prop-types';

import { Spacer, makeStyles } from '@bequestinc/wui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  withPadding: {
    padding: theme.spacing(2),
  },
  text: {
    fontSize: 15,
  },
}));

const BackButton = ({ onClick, ...props }) => {
  const { classes } = useStyles();

  const goBack = () => {
    if (onClick) {
      onClick();
      return;
    }
    Router.back();
  };

  return (
    <Button variant="text" onClick={goBack} className={classes.withPadding} {...props}>
      <ArrowBackIcon fontSize="small" />
      <Spacer h={4} />
      <Typography className={classes.text}>Back</Typography>
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: null,
};

export default BackButton;
