import { observer } from 'mobx-react';

import { logoutUser } from 'api';

import { makeStyles } from '@bequestinc/wui';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Divider, IconButton, MenuItem, MenuList, Paper } from '@mui/material';

import { useGlobalContext } from 'hooks';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import { ATTORNEY_MENU_ITEMS, LOGIN_SETTINGS_URL, PANEL_MENU_ITEMS } from 'utils/constants';

import FloatingUIPopper from 'components/FloatingUIPopper';

const useStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.tooltip,
  },
  button: {
    justifyContent: 'left',
    height: '100%',
    width: '100%',
    '&:hover': {
      background: 'none',
    },
  },
}));

const NavMenu = () => {
  const { classes } = useStyles();
  const { firm } = useGlobalContext();

  const { data: user } = useCurrentUserQuery();

  const loadItems = () => {
    if (user.isPanelUser) {
      return PANEL_MENU_ITEMS.filter(
        item => !item.permissions || user.groups.some(i => item.permissions.includes(i)),
      );
    }
    if ((user.isFirmUser || user.isAttorneyUser) && firm.name) {
      return ATTORNEY_MENU_ITEMS.filter(
        item =>
          (!item.permissions || user.groups.includes(item.permissions)) &&
          (firm.disabledByMalpractice ? item.showIfMalpracticeExpired : true) &&
          (firm.newCasesAllowed || !item.hideIfCantTakeNewCases),
      );
    }
    return [];
  };

  const renderAnchorElement = toggleElementProps => {
    return (
      <IconButton edge="end" aria-label="menu" size="large" {...toggleElementProps}>
        <MenuIcon />
      </IconButton>
    );
  };

  const renderContent = ({ setIsOpen }) => {
    const onClose = () => setIsOpen(false);

    return (
      <Paper variant="outlined">
        <MenuList autoFocus>
          {loadItems().map(({ component, href, description }) => (
            <MenuItem onClick={onClose} key={description}>
              <Button component={component} className={classes.button} href={href}>
                {description}
              </Button>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem onClick={onClose}>
            <Button
              className={classes.button}
              onClick={() =>
                LOGIN_SETTINGS_URL.then(url => {
                  window.location = url;
                })
              }
            >
              Login Settings
            </Button>
          </MenuItem>
          <MenuItem onClick={onClose}>
            <Button className={classes.button} onClick={logoutUser}>
              Sign Out
            </Button>
          </MenuItem>
        </MenuList>
      </Paper>
    );
  };

  return (
    <FloatingUIPopper renderAnchorElement={renderAnchorElement} renderContent={renderContent} />
  );
};

NavMenu.propTypes = {};

export default observer(NavMenu);
