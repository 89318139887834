import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import env from 'utils/env';

import Page from 'components/Page';

const ErrorFallback = ({ resetErrorBoundary }) => {
  const [supportPhoneNumber, setSupportPhoneNumber] = useState('');

  useEffect(() => {
    env.supportPhoneNumber.then(phoneNumber => setSupportPhoneNumber(phoneNumber));
  }, []);

  return (
    <Page title="Error">
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography align="center">Oops! Something went wrong</Typography>
        <Typography align="center">
          If refreshing your page or clicking "Try Again" doesn't work give us a call at{' '}
          {supportPhoneNumber}
        </Typography>
        <Button onClick={resetErrorBoundary} variant="contained" color="primary">
          Try Again
        </Button>
      </Stack>
    </Page>
  );
};

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default observer(ErrorFallback);
