import { observer } from 'mobx-react';

import Link from 'next/link';

import { makeStyles } from '@bequestinc/wui';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

import NavMenu from 'components/NavMenu';

const useStyles = makeStyles()(theme => ({
  appBar: {
    background: theme.palette.common.white,
    borderBottom: `2px solid #DBDCE2`,
  },
  logo: {
    maxHeight: 33,
  },
}));

const NavBar = () => {
  const { classes } = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Link href="/">
            <a>
              <img src="/images/mlp-logo.svg" alt="Go to the home page" className={classes.logo} />
            </a>
          </Link>
          <NavMenu />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default observer(NavBar);
