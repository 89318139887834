import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const createExtraFees = payload => CLIENT_POST_FILE.post('extra-fees/', payload);

export const getExtraFees = ({ limit = 0, offset = 0, params = {}, sortBy = '' }) => {
  const queryParams = createURLParams({ limit, offset, ordering: sortBy, ...params });
  return client.get('extra-fees/', { params: queryParams });
};

export const findAndUpdateExtraFee = (id, payload) => client.patch(`extra-fees/${id}/`, payload);

export const getExtraFeeHistoryData = id => client.get(`extra-fees/${id}/history/`);

export const removeExtraFeeDocument = id => client.delete(`extra-fees/documents/${id}/`);

export const getExtraFeeDocument = id => CLIENT_GET_FILE.get(`extra-fees/documents/${id}/`);

export const resendExtraFeesEmail = (id, notifyEmail) =>
  client.post(`extra-fees/${id}/resend-extra-fees-email/`, { notifyEmail });

export const emailExtraFeeDetails = async (id, emailAddresses) => {
  const { data } = await client.post(`extra-fees/${id}/email-extra-fee-details/`, {
    emailAddresses,
  });
  return data;
};
